import { AnimatedEl, AnimatedGroup } from '../../../../components';
import { Grid as BaseGrid, Button, Col, DocumentStyle, Row, Section } from '../../../../theme/components';
import { Heading, Text } from '../../../../theme/components/Typography';
import { RichText } from 'prismic-react-tools';
import { colors } from '../../../../theme';
import { get } from 'lodash';
import { mq } from 'styled-gen';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Grid = styled(BaseGrid)`
  & + & {
    margin-top: 3rem;

    ${mq.tablet(css`
      margin-top: 6rem;
    `)}
  }

  &:first-of-type {
    margin-top: -5rem;

    ${mq.tablet(css`
      margin-top: -10rem;
    `)}
  }

  &:last-of-type {
    margin-bottom: -5rem;

    ${mq.tablet(css`
      margin-bottom: -10rem;
    `)}
  }
`;

const ProductWrapper = styled(Row)`
  background-color: ${colors.n01};
  border-radius: 0.25rem;
  box-shadow: 0 0 2rem ${rgba(colors.n08, 0.16)};
`;

const ProductImage = styled.img`
  max-height: 100%;
  max-width: 100%;

  ${mq.tablet(css`
    min-height: 16.875rem;
  `)}
`;

export const ProductList = props => {
  const { buttonLabel, newProducts, productPageButtonLabel, products } = props;

  return (
    <Section noPadding zIndex={10}>
      {products.map(({ heading, image: { url: image }, page, text }, index) => {
        const pageUrl = get(page, 'uid');

        return (
          <AnimatedGroup key={index} parent={Grid}>
            <AnimatedEl parent={ProductWrapper}>
              <Col md={6} padding={{ xs: '2' }} xs={12}>
                <ProductImage src={image} />
              </Col>
              <Col
                md={5}
                padding={{ md: '2 2 2 null', xs: '2 2 2 2' }}
                textAlign={{ md: 'left', xs: 'center' }}
                xs={12}
              >
                <Heading h3>{heading}</Heading>
                <Text as="div" centeredOnTablet mt={1.5}>
                  <DocumentStyle>
                    <RichText content={text} />
                  </DocumentStyle>
                </Text>
                <Button href={`mailto:info@hydrustent.com?subject=Product interest: ${heading}`} mt={2}>
                  {buttonLabel}
                </Button>
                {pageUrl && (
                  <Button href={pageUrl} ml={2} primary>
                    {productPageButtonLabel}
                  </Button>
                )}
              </Col>
            </AnimatedEl>
          </AnimatedGroup>
        );
      })}
      <AnimatedGroup parent={Grid}>
        <AnimatedEl parent={ProductWrapper}>
          <Col xs={12}>
            <Text center large n05 padding={{ md: 6, xs: 3 }} semibold>
              {newProducts}
            </Text>
          </Col>
        </AnimatedEl>
      </AnimatedGroup>
    </Section>
  );
};

ProductList.propTypes = {
  buttonLabel: PropTypes.string,
  newProducts: PropTypes.string,
  productPageButtonLabel: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string
      }),
      page: PropTypes.shape({
        uid: PropTypes.string
      }),
      text: PropTypes.any
    })
  )
};
