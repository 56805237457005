const { orderBy } = require('lodash');

const filterAndSortDocuments = (documents, type, field, desc) =>
  orderBy(
    documents.filter(document => document.type === type),
    [`data.${field}`],
    [desc ? 'desc' : 'asc']
  ).reduce(
    (acc, current) => ({
      ...acc,
      [current.lang]: acc[current.lang]
        ? [...acc[current.lang], { uid: current.uid, ...current.data }]
        : [{ uid: current.uid, ...current.data }]
    }),
    {}
  );

module.exports = ({ documents }) => {
  const commonData = documents
    .filter(document => document.type === 'common')
    .reduce((acc, current) => ({ ...acc, [current.lang]: current.data }), {});

  const articles = filterAndSortDocuments(documents, 'page_-_article', 'date', true);
  const products = filterAndSortDocuments(documents, 'page_-_product', 'date', true);
  const productCategories = filterAndSortDocuments(documents, 'product_category', 'date', true);

  return { articles, commonData, productCategories, products };
};
