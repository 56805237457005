const langs = require('./langs-config');
const { isEmpty } = require('lodash');

const defaultLang = Object.keys(langs).reduce((prev, current) => (langs[current].default ? current : prev), null);

module.exports = ({ articles, commonData, productCategories, products, document }) => {
  let path;
  const { data, id, lang, type, uid } = document;

  let { pathPrefix } = data;

  if (!pathPrefix && type === 'page_-_article') {
    pathPrefix = 'news';
  }

  if (!pathPrefix && type === 'page_-_product') {
    pathPrefix = 'products';
  }

  if (uid === 'homepage') {
    path = `${langs[lang].default ? '' : langs[lang].path}/`;
  } else {
    path = `${langs[lang].default ? '' : langs[lang].path}/${pathPrefix ? `${pathPrefix}/` : ''}${uid || id}/`;
  }

  const common = commonData[lang] || commonData[defaultLang];

  const langList = Object.keys(commonData).reduce((prev, current) => {
    if (!isEmpty(commonData[current])) {
      return { ...prev, [current]: { ...langs[current], isActive: current === lang } };
    }

    return prev;
  }, {});

  const context = {
    common,
    document: {
      ...data,
      isLive: data.isLive !== 'no',
      type,
      uid
    },
    lang,
    langList,
    locale: langs[lang].path,
    path
  };

  if (uid === 'homepage') {
    context.articles = articles[defaultLang].slice(0, 4);
  }

  if (uid === 'news') {
    context.articles = articles[defaultLang];
  }

  if (type === 'page_-_product_aggregator') {
    context.products = products[defaultLang];
    context.productCategories = productCategories[defaultLang];
  }

  return context;
};
