import { Hero } from '../../components';
import { ProductList } from './components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const Products = props => {
  const {
    pageContext: { document }
  } = props;

  const hero = extractFromCamelCase(document, 'hero');
  const strings = extractFromCamelCase(document, 'string');
  const { productItems } = document;

  return (
    <>
      <Hero {...hero} />
      <ProductList products={productItems} {...strings} />
    </>
  );
};

Products.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(Products);
