import { Body, Heading } from './components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const Legal = props => {
  const {
    pageContext: { document, common }
  } = props;

  const { backToHome: backToHomeLabel } = extractFromCamelCase(common, 'strings');
  const { body, date, heading } = document;

  const headingData = { backToHomeLabel, date, heading };

  return (
    <>
      <Heading {...headingData} />
      <Body content={body} />
    </>
  );
};

Legal.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(Legal);
